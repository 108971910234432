<template>
    <a :class="{ 'is-open': isOpen }" href="#" class="burger" @click.prevent="toggleMe">
        <div class="burger__bar" />
        <div class="burger__bar" />
    </a>
</template>

<script>
export default {
    name: 'BurgerComponent',
    data() {
        return {
            isOpen: false
        }
    },
    methods: {
        toggleMe() {
            this.isOpen = !this.isOpen;
            this.$emit('toggle', this.isOpen);
        }
    },
}
</script>

<style scoped lang="scss">

   @import '~@/assets/styles/abstracts/variables';
    @import '~bootstrap/scss/mixins';

    // Burger
    //
    // Boutton burger
    //
    // Markup:
    //  <burger-component/>
    //
    // Styleguide components.burger

    .burger {

        $self: &;

        display: flex;
        flex-direction: column;
        justify-content: center;
        //align-items: center;
        width: 40px;
        height: 40px;

        &__bar {
            height: 2px;
            width: 100%;
            display: block;
            background-color: $gray-800;

            transition: 300ms ease-in-out;

            &:nth-child(2) {
                margin-top: 13px;
                width: 70%;
            }
        }

        &.is-open {
            #{ $self }__bar {
                background-color: $white;

                &:nth-child(1) {
                    transform: rotate(-135deg);
                }

                &:nth-child(2) {
                    width: 100%;
                    margin-top: 0;
                    transform: rotate(135deg);
                }

            }
        }
    }

</style>
