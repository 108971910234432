var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"VueCarousel"},[_c('div',{ref:"VueCarousel-wrapper",staticClass:"VueCarousel-wrapper"},[_c('div',{ref:"VueCarousel-inner",staticClass:"VueCarousel-inner",style:({
                'transform': `translate3d(${_vm.currentOffset}px, 0, 0)`,
                'transition': _vm.dragging ? 'none' : _vm.transitionStyle,
                'ms-flex-preferred-size': `${_vm.slideWidth}px`,
                'webkit-flex-basis': `${_vm.slideWidth}px`,
                'flex-basis': `${_vm.slideWidth}px`,
                'visibility': _vm.slideWidth ? 'visible' : 'hidden',
                'padding-left': `${_vm.padding}px`,
                'padding-right': `${_vm.padding}px`
            }),attrs:{"role":"listbox"}},[_vm._t("default")],2)]),(_vm.paginationEnabled && _vm.pageCount > 0)?_c('vue-pagination',{on:{"paginationclick":function($event){return _vm.goToPage($event, 'pagination')}}}):_vm._e(),(_vm.navigationEnabled)?_c('vue-navigation',{attrs:{"click-target-size":_vm.navigationClickTargetSize,"next-label":_vm.navigationNextLabel,"prev-label":_vm.navigationPrevLabel},on:{"navigationclick":_vm.handleNavigation}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }