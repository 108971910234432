<template>
    <div class="carousel-product-component">
        <div class="carousel-product-component__slides">
            <slot />
        </div>
        <div v-if="slides.length > 1" class="carousel-product-component__nav">
            <div class="carousel-product-component-nav">
                <a
                    ref="prev"
                    :class="{'is-disabled':currentIndex === 0}"
                    href="#"
                    class="carousel-product-component-nav__navButton carousel-product-component-nav__navButton--prev"
                    @click.prevent="prev"
                >
                    <svg class="" height="40" viewBox="0 0 24 24" width="40" xmlns="http://www.w3.org/2000/svg">
                        <path d="M15.41 16.09l-4.58-4.59 4.58-4.59L14 5.5l-6 6 6 6z" />
                    </svg>
                </a>
                <div ref="thumbs" class="carousel-product-component-nav__thumbs">
                    <div
                        v-for="(slide, index) in slides"
                        :key="index"
                        :ref="`thumbCol${index}`"
                        class="carousel-product-component-nav__thumb-col"
                    >
                        <a
                            :ref="`thumb${index}`"
                            :index="index"
                            :class="{ 'carousel-product-component-nav__thumb--is-current' : currentIndex === index}"
                            :style="imgUrl(slide)"
                            href="#"
                            class="carousel-product-component-nav__thumb"
                            @click.prevent="goto(index)"
                        />
                    </div>
                </div>
                <a
                    ref="next"
                    :class="{'is-disabled':currentIndex === slides.length - 1}"
                    href="#"
                    class="carousel-product-component-nav__navButton carousel-product-component-nav__navButton--next"
                    @click.prevent="next"
                >
                    <svg class="" height="40" viewBox="0 0 24 24" width="40" xmlns="http://www.w3.org/2000/svg">
                        <path d="M8.59 16.34l4.58-4.59-4.58-4.59L10 5.75l6 6-6 6z" />
                        <path d="M0-.25h24v24H0z" fill="none" />
                    </svg>
                </a>
            </div>
        </div>
    </div>
</template>

<script>
import gsap from 'gsap';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';

gsap.registerPlugin(ScrollToPlugin);

export default {
    name: 'CarouselProductComponent',
    provide() {
        return {
            carousel: this
        };
    },
    data() {
        return {
            currentIndex: 0, // L'index courrant
            slides: [] // La liste des slides
        }
    },
    computed: {

        /**
         * Retourne la largeur d'une vignette
         * @returns {number}
         */
        thumbWidth() {
            let el = this.$refs.thumbCol0 && this.$refs.thumbCol0[0] || null;
            let width = el && el.clientWidth;
            return width || 0;
        }
    },
    watch: {

        /**
         * Écoute le changement d'index
         */
        currentIndex() {

            // On positionne la vignette sélectionnée complètement à gauche
            let currentScrollLeft = this.currentIndex * this.thumbWidth;
            gsap.to(this.$refs.thumbs, {
                duration: 0.3,
                ease: 'power2.inOut',
                scrollTo: {
                    x: currentScrollLeft
                },
            });
        }
    },
    mounted() {
        this.slides = this.$children;
    },
    methods: {
        next() {
            if (this.currentIndex < this.slides.length - 1) {
                this.currentIndex++;
            }
        },

        prev() {
            if (this.currentIndex > 0) {
                this.currentIndex--;
            }
        },

        goto(index) {
            this.currentIndex = index;
        },

        /**
         * Retourne l'url de l'image d'une slide
         * @param slide
         * @returns {string}
         */
        imgUrl(slide) {
            return `background-image:url('${slide.$el.getElementsByTagName('img')[0].src}');`
        }
    },
}
</script>

<style scoped lang="scss">
@import '@/assets/styles/abstracts/_variables.scss';
@import '@/assets/styles/abstracts/_placeholders.scss';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

.carousel-product-component {
    position: relative;

    &__slides {
        background-color: #00a4db;

        position: relative;
        width: 100%;
        padding-top: 100%; /* 1:1 Aspect Ratio */
    }

    &__nav {
        margin-top: 3rem;
    }
}

.carousel-product-component-nav {

    $thumb-gutter: 8%;
    $thumb-gutter-md: 8%;
    $thumb-gutter-lg: 16%;
    $thumb-gutter-xl: 11%;
    $thumb-width: 68px;

    display: flex;
    align-items: center;

    &__thumbs {
        flex: 1;
        width: 300px;
        display: flex;

        overflow-y: hidden;
        overflow-x: scroll;
        -webkit-overflow-scrolling: touch;

        @include media-breakpoint-up(md) {
            overflow: hidden;
        }

        &::-webkit-scrollbar {
            width: 0px; /* remove scrollbar space */
            background: transparent; /* optional: just make scrollbar invisible */
        }
    }

    &__thumb-col {
        position: relative;
        flex: 0 0 auto;

        &:not(:last-child) {
            padding-right: $thumb-gutter;
            @include media-breakpoint-up(md) {
                padding-right: $thumb-gutter-md;
            }
            @include media-breakpoint-up(lg) {
                padding-right: $thumb-gutter-lg;
            }
            @include media-breakpoint-up(xl) {
                padding-right: $thumb-gutter-xl;
            }
        }

        &::before {
            content: '';
            width: 1px;
            margin-left: -1px;
            float: left;
            height: 0;
            padding-top: 100%;
        }

        &::after {
            content: '';
            display: table;
            clear: both;
        }

    }

    &__thumb {
        position: relative;
        display: block;
        background-color: $gray-300;
        background-size: cover;
        background-repeat: no-repeat;
        height: $thumb-width;
        width: $thumb-width;

        &--is-current {
            &::after {
                content: '';
                position: absolute;
                //border: 2px solid $gray-500;
                left: 0;
                right: 0;
                bottom: 0;
                height: 3px;
                background-color: $primary;
            }
        }
    }

    &__navButton {
        display: none;

        &.is-disabled {
            pointer-events: none;
            cursor: auto;
            opacity: 0.2;
        }

        &--prev {

            @include media-breakpoint-up(md) {
                display: block;
                padding: 1rem 2.4rem 1rem 0;
            }
            @include media-breakpoint-up(lg) {
                padding: 1rem 4.5rem 1rem 0;
            }

            > svg {
                margin-left: -1rem;
            }
        }

        &--next {
            @include media-breakpoint-up(md) {
                display: block;
                padding: 1rem 0 1rem 2.4rem;
            }
            @include media-breakpoint-up(lg) {
                padding: 1rem 0 1rem 4.5rem;
            }

            > svg {
                margin-right: -1rem;
            }
        }
    }
}
</style>
