<template>
    <transition name="fade" mode="out-in">
        <div v-show="currentIndex === myIndex" class="carousel-product-slide-component">
            <slot />
        </div>
    </transition>
</template>

<script>
export default {
    name: 'CarouselProductSlideComponent',
    inject: ['carousel'],
    props: {
        myIndex: {
            type: Number,
            default: 0
        },
        tata: {
            type: String,
            default: ''
        }
    },
    computed: {
        currentIndex() {
            return this.carousel.currentIndex;
        }
    },
}
</script>

<style lang="scss">

   @import '~@/assets/styles/abstracts/variables';

    .carousel-product-slide-component {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background-color: $gray-300;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
            //width: 100%;
            height: auto;
            max-width: 100%;
            max-height: 100%;
        }
    }

    .fade-enter-active, .fade-leave-active {
        transition: opacity .5s;
    }

    .fade-enter,
    .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
        opacity: 0;
    }
</style>
