<template>
    <div v-show="vueCarousel.pageCount > 1" class="VueCarousel-pagination">
        <ul class="VueCarousel-dot-container" role="tablist">
            <li
                v-for="(page, index) in pagniationCount"
                :key="`${page}_${index}`"
                :aria-selected="isCurrentDot(index) ? 'true' : 'false'"
                :class="{ 'VueCarousel-dot--active': isCurrentDot(index) }"
                :style="`
          margin-top: ${vueCarousel.paginationMargin}px;
          padding: ${vueCarousel.paginationPadding}px;
        `"
                class="VueCarousel-dot"
                aria-hidden="false"
                role="presentation"
                @click="goToPage(index)"
            >
                <button
                    :title="`Item ${index}`"
                    :tabindex="0"
                    :style="`
            width: ${vueCarousel.paginationSize.width}px;
            height: ${vueCarousel.paginationSize.height}px;
            background: ${isCurrentDot(index) ? vueCarousel.paginationActiveColor : vueCarousel.paginationColor};
          `"
                    type="button"
                    role="button"
                    aria-label="`Item ${index}`"
                    class="VueCarousel-dot-button"
                />
            </li>
        </ul>
    </div>
</template>

<script>
export default {
    name: 'VuePagination',
    inject: ['vueCarousel'],
    computed: {
        pagniationCount() {
            return this.vueCarousel.scrollPerPage
                ? this.vueCarousel.pageCount
                : this.vueCarousel.slideCount - 2;
        }
    },
    methods: {
    /**
     * Change page by index
     * @param {number} index
     * return {void}
     */
        goToPage(index) {
            /**
       * @event paginationclick
       * @type {number}
       */
            this.$emit('paginationclick', index);
        },

        /**
     * Check on current dot
     * @param {number} index - dot index
     * @return {boolean}
     */
        isCurrentDot(index) {
            return index === this.vueCarousel.currentPage;
        }
    }
};

</script>

<style lang="scss">
    .VueCarousel-pagination {
        width: 100%;
        text-align: center;
    }

    .VueCarousel-dot-container {
        display: inline-block;
        margin: 0 auto;
        padding: 0;
    }

    .VueCarousel-dot {
        display: inline-block;
        cursor: pointer;
    }

    .VueCarousel-dot-button {
        appearance: none;
        border: 0;
        background-color: transparent;
        padding: 0;
        //border-radius: 100%;
        outline: none;
        cursor: pointer;
    }

    .VueCarousel-dot-button:focus {
        outline: 0;
    }
</style>
