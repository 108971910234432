import 'assets/styles/main.scss';
import Vue from 'vue';
import HeaderComponent from 'app/components/HeaderComponent';
import BurgerComponent from 'app/components/BurgerComponent';
import CarouselComponent from 'app/components/carousel/CarouselComponent';
import CarouselSlideComponent from 'app/components/carousel/CarouselSlideComponent';
import VueCarousel from 'app/components/carousel-testimonials/VueCarousel';
import VueSlide from 'app/components/carousel-testimonials/VueSlide';
import VuePagination from 'app/components/carousel-testimonials/VuePagination';
import VueNavigation from 'app/components/carousel-testimonials/VueNavigation';
import CarouselProductComponent from 'app/components/carousel-product/carousel-product-component';
import CarouselProductSlideComponent from 'app/components/carousel-product/carousel-product-slide-component';
import DropdownComponent from 'app/components/dropdown/DropdownComponent';
import EventsNavComponent from 'app/components/events-nav/EventsNavComponent';
import DocumentComponent from 'app/components/document/DocumentComponent';
import CsrfInput from 'app/components/CsrfInput.vue';
import FlashMessage from 'app/components/FlashMessage.vue';
import PopIn from 'app/components/PopIn.vue';

// injecte le public path au runtime car pas connu au build. __webpack_public_path__
/* eslint-disable */
if (window.runtime_webpack_public_path) {
    __webpack_public_path__ =  window.runtime_webpack_public_path;
}
/* eslint-enable */

new Vue({
    el: '#app',
    components: {
        HeaderComponent,
        BurgerComponent,
        CarouselComponent,
        CarouselSlideComponent,
        VueCarousel,
        VueSlide,
        VuePagination,
        VueNavigation,
        CarouselProductComponent,
        CarouselProductSlideComponent,
        DropdownComponent,
        EventsNavComponent,
        DocumentComponent,
        CsrfInput,
        FlashMessage,
        PopIn
    },
    created() {}
});
