<template>
    <div class="carousel">
        <div class="carousel__slides">
            <carousel-slide-component
                v-for="(slide, i) in slidesData"
                :key="i"
                :slide-data="slide"
                :index="i"
                :slides-count="slidesData.length"
                @requestNextEvent="onNextRequest"
                @requestPrevEvent="onPrevRequest"
                @requestGotoEvent="onGotoRequest"
            />
        </div>
        <div class="carousel__nav">
            <button class="button button--primary button--sm" @click.prevent="prev">
                Précédent
            </button>
            <button class="button button--primary button--sm" @click.prevent="next">
                Suivant
            </button>
        </div>
        <div class="carousel__pagination">
            <button
                v-for="(slide, i) in slidesData"
                :key="i"
                :class="{active: index === i}"
                class="button button--primary button--sm"
                @click="goto(i)"
            >
                {{ i }}
            </button>
        </div>
    </div>
</template>

<script>
import CarouselSlideComponent from './CarouselSlideComponent';

export default {
    name: 'CarouselComponent',
    components: {
        CarouselSlideComponent
    },

    props: {
        slidesData: {
            type: Array,
            default() {
                return []
            }
        },
        delay: {
            type: Number,
            default: 8
        },
        autoPlay: {
            type: Boolean,
            default: false
        }
    },

    data() {
        return {
            index: null,
            direction: null,
            intervalId: null
        }
    },

    computed: {
        slidesCount() {
            return this.slidesData.length;
        }
    },

    mounted: function () {
        this.index = 0;

        if (this.autoPlay && this.delay > 0) {
            this.startAutoPlay();
        }
    },

    methods: {
        next() {
            this.index++;
            this.direction = 'right';
            if (this.index >= this.slidesCount) {
                this.index = 0;
            }
            this.startAutoPlay();
        },

        prev() {
            this.index--;
            this.direction = 'left';
            if (this.index < 0) {
                this.index = this.slidesCount - 1;
            }
            this.startAutoPlay();
        },

        goto(i) {
            this.direction = i > this.index ? 'right' : 'left';
            this.index = i;
            this.startAutoPlay();
        },

        onNextRequest() {
            this.next();
        },

        onPrevRequest() {
            this.prev();
        },

        onGotoRequest({index}) {
            this.goto(index);
        },

        startAutoPlay() {
            clearInterval(this.intervalId);
            this.intervalId = setInterval(() => this.next(), this.delay * 1000);
        }
    }
}
</script>

<style scoped lang="scss">
@import '~@/assets/styles/abstracts/variables';
@import '~bootstrap/scss/mixins';

.carousel {
    position: relative;
    background-color: $white;
    z-index: 0;

    &__slides {
        position: relative;
        overflow: hidden;
        height: 346px;

        @include media-breakpoint-up(md) {
            height: 540px;
        }
    }

    &__nav {
        z-index: 20;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        justify-content: space-between;
        background-color: #0a7ac2;
        width: 100%;

        display: none;

        > * {
            flex: 0 0 auto;
        }
    }

    &__pagination {
        z-index: 30;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        text-align: center;

        display: none;
    }
}
</style>
