<template>
    <div v-if="!hidden">
        <div class="pop-in__overlay" />
        <div class="pop-in__container">
            <p class="pop-in__heading">
                {{ heading }}
            </p>
            <p class="pop-in__question">
                {{ question }}
            </p>
            <div class="pop-in__buttons">
                <button class="btn btn-primary" @click="close">
                    {{ yestext }}
                </button>
                <button class="btn btn-primary" @click="goToLink">
                    {{ notext }}
                </button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'PopIn',

    props: {
        heading: { type: String, required: true },
        question: { type: String, required: true },
        yestext: { type: String, required: true },
        notext: { type: String, required: true },
        nolink: { type: String, required: true },
    },

    data() {
        return {
            hidden: true,
        };
    },

    mounted() {
        const iAmPro = localStorage.getItem('i-am-pro') || null;
        this.hidden = iAmPro !== null;
    },

    methods: {
        close() {
            this.hidden = true;
            localStorage.setItem('i-am-pro', true);
        },

        goToLink() {
            localStorage.setItem('i-am-pro', false);
            window.location.href = this.nolink;
        },
    },
}
</script>

<style lang="scss" scoped>

@import '~@/assets/styles/abstracts/variables';
.pop-in {
    &__overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100svh;
        background-color: rgba(0, 0, 0, 0.4);
        z-index: 100;
    }

    &__container {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: calc(100vw - 30px);
        max-width: 768px;
        max-height: calc(100svh - 30px);
        overflow-y: scroll;
        background-color: $white;
        padding: 80px 20px;
        z-index: 101;
        display: flex;
        flex-direction: column;
        align-items: center;

        @media (max-width: 767px) {
            padding: 40px 20px;
        }
    }

    &__heading {
        color: $gray-900;
        font-size: 34px;
        font-style: normal;
        font-weight: 300;
        line-height: 1.3;
        text-align: center;
        margin-bottom: 8px;

        @media (max-width: 767px) {
            font-size: 24px;
        }
    }

    &__question {
        color: $gray-700;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 1.5;
        text-align: center;
        margin-bottom: 32px;
    }

    &__buttons {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 17px;
    }
}
</style>
