<template>
    <div v-click-outside="closePicker" class="event-nav-component d-flex flex-wrap justify-content-between justify-content-md-center align-items-md-baseline">
        <a href="#" class="link link--arrow-left link--lg d-block mr-md-5" @click.prevent="goPrev">{{ i18n.previousMounth }}</a>
        <h2 class="event-nav-component__label mb-2 mb-md-0 d-flex justify-content-center order-first flex-grow-1 order-md-0 flex-md-grow-0">
            <span>{{ label }}</span>
            <button ref="buttonCal" class="event-nav-component__btn btn p-0 ml-2" @click.prevent="openPicker">
                <svg class="icon icon--xl">
                    <use href="#icon-calendar" xlink:href="#icon-calendar" />
                </svg>
            </button>
        </h2>
        <datepicker
            ref="picker"
            :minimum-view="'month'"
            :maximum-view="'month'"
            :calendar-button="false"
            :bootstrap-styling="false"
            :language="local || en"
            calendar-class="event-nav-component__calendar"
            input-class="event-nav-component__input"
            @selected="dateSelectedHandler"
        />
        <a href="#" class="link link--arrow-right link--lg d-block ml-md-5" @click.prevent="goNext">{{ i18n.nextMounth }}</a>
    </div>
</template>

<script>

import Datepicker from 'vuejs-datepicker';
import en from 'vuejs-datepicker/src/locale/translations/en';
import es from 'vuejs-datepicker/src/locale/translations/es';
import ClickOutside from 'vue-click-outside';

export default {
    name: 'EventsNavComponent',
    directives: {
        ClickOutside
    },
    components: {
        Datepicker
    },
    props: {
        slug: {
            type: String,
            default: null
        },
        baseUrl: {
            type: String,
            default: ''
        },
        label: {
            type: String,
            default: ''
        },
        i18n: {
            type: Object,
            default: function () {
                return {
                    'previousMounth': 'Previous mounth',
                    'nextMounth': 'Next mounth'
                };
            }
        },
        language: {
            type: String,
            default: 'en'
        }
    },
    data() {
        return {
            currentDate: new Date(),
            en: en,
            es: es
        };
    },
    computed: {
        local() {
            return this[this.language];
        }
    },
    created() {
        // On créer une date une fonciton du slug.
        this.currentDate = this.slug !== null && this.slug !== 'today' ? new Date(this.slug) : new Date();
    },
    methods: {
        openPicker() {
            this.$refs.picker.showCalendar();
        },
        closePicker() {
            if(this.$refs.picker.isOpen) {
                this.$refs.picker.showCalendar();
            }
        },
        goPrev() {
            let d = new Date(this.currentDate);
            d.setDate(1);
            d.setMonth(d.getMonth() - 1);
            let url = `${this.baseUrl}/${d.toISOString().slice(0, 7)}`;
            window.location = url;
        },
        goNext() {
            let d = new Date(this.currentDate);
            d.setDate(1);
            d.setMonth(d.getMonth() + 1);
            let url = `${this.baseUrl}/${d.toISOString().slice(0, 7)}`;
            window.location = url;
        },
        goTo(value) {
            let d = new Date(value);
            let url = `${this.baseUrl}/${d.toISOString().slice(0, 7)}`;
            window.location = url;
        },
        dateSelectedHandler(value) {
            this.goTo(value);
        }
    },
};
</script>

<style lang="scss">

    @import '~@/assets/styles/abstracts/_mixins.scss';
    @import '~@/assets/styles/abstracts/_variables.scss';
    @import '~@/assets/styles/abstracts/_placeholders.scss';
    @import '~@/assets/sprites/generated/sprite.scss';
    @import '~bootstrap/scss/functions';
    @import '~bootstrap/scss/variables';
    @import '~bootstrap/scss/mixins';

    .event-nav-component {
        position: relative;

        .vdp-datepicker {
            //position: unset;
            width: 300px;
            position: absolute;
            top: 50px;
            left: 0;
            right: 0;
            margin: 0 auto;
        }

        &__input {
            display: none;
        }

        &__calendar {
            width: 100%;
        }

        &__btn {
            border: 0;
            background-color: $white;
        }

        &__label {
            //@include lh-crop(1);
            flex-basis: 100%;
            text-align: center;

            @include media-breakpoint-up(md) {
                flex-basis: auto;
            }
        }

    }

</style>
