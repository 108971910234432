<template>
    <div v-click-outside="hideDropdown" class="dropdown-component" tabindex="-1">
        <button
            id="dropdownCategoriesLink"
            :aria-expanded="isOpen"
            class="dropdown-component__button"
            role="button"
            aria-haspopup="true"
            @click="toggleDropdown"
        >
            <span>{{ label }}</span><span
                ref="carret"
                :class="[isOpen ? 'icon-arrow-down' : 'icon-arrow-up']"
                class="dropdown-component__button-carret"
            >
                <svg width="11" height="7" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M5.531 4.761L9.646.646a.5.5 0 0 1 .708.708L5.537 6.17.649 1.356a.5.5 0 1 1 .702-.712L5.53 4.76z"
                        fill-rule="evenodd"
                    />
                </svg>
            </span>
        </button>
        <div v-show="isOpen" aria-labelledby="dropdownCategoriesLink" class="dropdown-component__dropdown-menu">
            <a
                v-for="(item, index) in items"
                :key="index"
                :href="item.url"
                class="dropdown-component__dropdown-menu-item"
            >
                {{ item.label }}
            </a>
        </div>
    </div>
</template>

<script>
import ClickOutside from 'vue-click-outside';

export default {
    name: 'DropdownComponent',
    directives: {
        ClickOutside
    },
    props: {
        label: {
            type: String,
            default: 'coucou'
        },
        items: {
            type: Array,
            default: function () {
                return [];
            }
        }
    },
    data() {
        return {
            isOpen: false
        };
    },
    methods: {
        showDropdown() {
            this.isOpen = true;
            this.$el.focus();
        },
        hideDropdown() {
            this.isOpen = false;
            this.$el.blur();
        },
        toggleDropdown() {
            if (this.isOpen) {
                this.hideDropdown();
            } else {
                this.showDropdown();
            }
        }
    },
};
</script>

<style lang="scss" scoped>

    @import '~@/assets/styles/abstracts/_variables.scss';
    @import '~@/assets/styles/abstracts/_placeholders.scss';
    @import '~@/assets/sprites/generated/sprite.scss';
    @import '~bootstrap/scss/functions';
    @import '~bootstrap/scss/variables';
    @import '~bootstrap/scss/mixins';

    .dropdown-component {

        $self: &;

        $border-radius: 4px;
        $padding-x: 13px;
        $padding-y: 14px;

        $button-background-color: $white;

        $zindex-dropdown: $zindex-dropdown;
        $dropdown-padding-y: 0;
        $dropdown-padding-x: 0;
        $dropdown-spacer: -2px;
        $dropdown-bg: $white;
        $dropdown-border-color: $gray-500;
        $dropdown-border-width: 1px;

        $dropdown-item-padding-y: $padding-y;
        $dropdown-item-padding-x: $padding-x;
        $dropdown-item-separator-border: 1px $dropdown-border-color solid;

        $dropdown-link-color: $gray-600;
        $dropdown-link-hover-color: $white;
        $dropdown-link-active-bg: $dropdown-link-active-bg;

        position: relative;

        // Le bouton
        &__button {

            @extend %font-Arial;

            display: flex;
            align-items: center;
            width: 100%;
            text-align: left;
            white-space: nowrap;
            user-select: none;
            border: $dropdown-border-width solid $dropdown-border-color;
            color: $gray-900;
            background-color: $button-background-color;

            padding: $padding-x $padding-y;
            font-size: 1.6rem;
            border-radius: $border-radius;

            // Share hover and focus styles
            @include hover-focus {
                text-decoration: none;
            }

            > span:not(#{$self}__button-carret) {
                flex: 1;
            }

            &:focus,
            &.focus {
                outline: 0;
                box-shadow: $btn-focus-box-shadow;
            }

            // Disabled comes first so active can properly restyle
            &.disabled,
            &:disabled {
                @include box-shadow(none);
                opacity: $btn-disabled-opacity;
            }

            // Opinionated: add "hand" cursor to non-disabled .btn elements
            &:not(:disabled):not(.disabled) {
                cursor: pointer;
            }

            &:not(:disabled):not(.disabled):active,
            &:not(:disabled):not(.disabled).active {
                @include box-shadow($btn-active-box-shadow);

                &:focus {
                    @include box-shadow($btn-focus-box-shadow, $btn-active-box-shadow);
                }
            }
        }

        // Le menu
        &__dropdown-menu {
            position: absolute;
            top: 100%;
            left: 0;
            right: 0;
            z-index: $zindex-dropdown;
            padding: $dropdown-padding-y $dropdown-padding-x;
            margin: $dropdown-spacer 0 0; // override default ul
            font-size: $font-size-base; // Redeclare because nesting can cause inheritance issues
            color: $body-color;
            text-align: left; // Ensures proper alignment if parent has it changed (e.g., modal footer)
            list-style: none;
            background-color: $dropdown-bg;
            background-clip: padding-box;
            border: $dropdown-border-width solid $dropdown-border-color;
            border-radius: $border-radius;
            border-top-left-radius: 0;
            border-top-right-radius: 0;
        }

        // Un item du menu
        &__dropdown-menu-item {
            //

            display: block;
            width: 100%; // For `<button>`s
            padding: $dropdown-item-padding-y $dropdown-item-padding-x;
            clear: both;
            font-weight: $font-weight-normal;
            color: $dropdown-link-color;
            text-align: inherit; // For `<button>`s
            //white-space: nowrap; // prevent links from randomly breaking onto new lines
            background-color: transparent; // For `<button>`s
            border: 0; // For `<button>`s

            @include hover-focus {
                color: $dropdown-link-hover-color;
                text-decoration: none;
                background-color: $dropdown-link-active-bg;
            }

            &:focus,
            &.focus {
                outline: 0;
                box-shadow: $btn-focus-box-shadow;
            }

            &.active,
            &:active {
                color: $dropdown-link-active-color;
                text-decoration: none;
            }

            &.disabled,
            &:disabled {
                color: $dropdown-link-disabled-color;
                background-color: transparent;
            }

            & + & {
                border-top: $dropdown-item-separator-border;
            }
        }

        // Le carret
        &__button-carret {
            flex: 0;

            svg {
                transition: transform 0.2s;
            }
        }

        .icon-arrow-down {
            svg {
                transform: rotate(180deg);
            }

            path {
                fill: $primary;
            }
        }

        .icon-arrow-up {
            path {
                fill: $primary;
            }
        }
    }
</style>
