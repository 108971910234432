<template>
    <div class="carousel-slide">
        <div
            :id="'image' + index"
            ref="image"
            :style="imageStyle"
            class="carousel-slide__image"
        >
            <div v-show="!isImageLoaded" class="carousel-slide__loading">
                <img src="./loading.svg" alt="">
            </div>
        </div>
        <div class="carousel-slide__content">
            <h2 :id="'title' + index" ref="title" class="carousel-slide__title">
                {{ slideData.title }}
            </h2>
            <p ref="body" class="carousel-slide__body lead">
                {{ slideData.body }}
            </p>

            <div class="carousel-slide__nav">
                <div ref="nav" class="carousel-slide-nav">
                    <a
                        v-for="n in slidesCount"
                        :key="n"
                        :class="{active: n - 1 === $parent.index}"
                        href="#"
                        class="carousel-slide-nav__item"
                        @click.prevent="navItemClickHandler(n)"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import gsap from 'gsap';
import imagesLoaded from 'imagesloaded';

export default {
    name: 'CarouselSlideComponent',
    props: {
        index: {
            type: Number,
            default: 0
        },
        slideData: {
            type: Object,
            default() {
                return {
                    img: '',
                    title: '',
                    body: ''
                }
            }
        },
        slidesCount: {
            type: Number,
            default: 0
        }
    },

    data() {
        return {
            tl: null,
            isImageLoaded: false
        }
    },

    computed: {
        isVisible() {
            return this.index === this.$parent.index;
        },

        transition() {
            if (this.$parent.direction) {
                return 'slide-' + this.$parent.direction;
            }
            return null;
        },

        imageStyle() {
            return `background-image: url("${this.slideData.img}"); background-color: #4989f3`;
        }
    },

    watch: {
        isVisible: function (newValue) {
            //console.log(newValue, oldValue);
            if (newValue) {
                this.showMe();
            } else {
                this.hideMe();
            }
        }
    },

    mounted() {
        //console.log(this.index, 'mounted');
        //let $box = this.$refs.title;
        this.tl = gsap.timeline({
            paused: true,
            onComplete: () => {
                //this.$el.style.zIndex = 7;
            },
            onReverseComplete: () => {
                this.$el.style.display = 'none';
            }
        });
        this.tl.from(this.$refs.image, 0.4, {alpha: 0, delay: 0.5});
        this.tl.from(this.$refs.title, 0.3, {x: 20, alpha: 0}, '-=0.1');
        this.tl.from(this.$refs.body, 0.3, {x: 20, alpha: 0}, '-=0.1');
        this.tl.staggerFrom(this.$refs.nav.childNodes, 0.3, {y: 100, alpha: 0}, 0.1, '-=0.1');

        this.hideMe();

        let images = document.querySelector(`#image${this.index}`);
        imagesLoaded(images, {background: true}, () => {
            //console.log('Image loaded!', e);
            this.isImageLoaded = true;
        });
    },

    methods: {
        showMe() {
            this.$el.style.display = 'flex';
            this.$el.style.zIndex = 7;
            this.tl.timeScale(1);
            this.tl.play(0);
        },

        hideMe() {
            this.$el.style.zIndex = 0;
            this.tl.timeScale(1.5);
            this.tl.reverse();
        },

        navItemClickHandler(n) {
            this.$emit('requestGotoEvent', {'index': n - 1});
        },
    },
}
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/abstracts/variables';
@import '~@/assets/styles/abstracts/placeholders';
@import '~bootstrap/scss/mixins';

.carousel-slide {
    z-index: 0;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    display: flex;

    &__image {
        position: relative;

        width: 50%;
        display: none;

        background-size: cover !important;
        background-repeat: no-repeat;
        background-color: $primary;
        background-position: center;

        @include media-breakpoint-up(md) {
            display: block;
        }
    }

    &__content {
        position: relative;
        flex: 1;
        padding: 4rem $container-padding-xs;

        @include media-breakpoint-up(md) {
            padding: 4.7rem $grid-gutter-width;
        }

        @include media-breakpoint-up(lg) {
            padding: 10rem 11.8rem;
        }
    }

    &__title,
    &__body {
        position: relative;
    }

    &__title {
        @extend %display-1;
    }

    &__body {
        margin-top: 2.3rem;

        @include media-breakpoint-up(md) {
            margin-top: 2.6rem;
        }
    }

    &__nav {

        @include media-breakpoint-up(md) {
            margin-top: 4rem;
        }
    }

    &__loading {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-position: center;
        background-repeat: no-repeat;

        display: flex;
        justify-content: center;
        align-items: center;
    }
}

.carousel-slide-nav {
    $self: &;

    display: flex;

    &__item {
        position: relative;
        flex: 0 0 auto;;
        height: 1.6rem;
        padding: 0.8rem 1rem 0.8rem 0;

        display: flex;
        justify-content: center;
        align-items: center;

        &::before {
            content: '';
            width: 5rem;
            height: 2px;
            background-color: $gray-500;
        }

        &:hover,
        &:active,
        &.active {
            &::before {
                content: '';
                width: 5rem;
                height: 2px;
                background-color: $primary;
            }
        }
    }
}
</style>
