<template>
    <input type="hidden" :name="csrfToken.name" :value="csrfToken.value">
</template>

<script>
export default {
    name: 'CsrfInput',

    data() {
        return {
            csrfToken: {
                name:  window.Craft.csrfTokenName,
                value: window.Craft.csrfTokenValue,
            }
        };
    }
};
</script>
