<template>
    <div
        v-if="!hidden"
        :role="role"
        class="flash-message"
        :class="{
            'flash-message--error': isError,
            'flash-message--success': !isError,
        }"
    >
        <div class="flash-message__content">
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="32px"
                height="32px"
                viewBox="0 0 24 24"
                fill="#ffffff"
                class="mr-2"
            >
                <g v-if="!isError">
                    <path d="M0 0h24v24H0V0z" fill="none" />
                    <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zM9.29 16.29L5.7 12.7c-.39-.39-.39-1.02 0-1.41.39-.39 1.02-.39 1.41 0L10 14.17l6.88-6.88c.39-.39 1.02-.39 1.41 0 .39.39.39 1.02 0 1.41l-7.59 7.59c-.38.39-1.02.39-1.41 0z" />
                </g>
                <path
                    v-else
                    d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 11c-.55 0-1-.45-1-1V8c0-.55.45-1 1-1s1 .45 1 1v4c0 .55-.45 1-1 1zm1 4h-2v-2h2v2z"
                />
            </svg>

            <slot />
        </div>

        <div class="flash-message__close" @click="close">
            ×
        </div>
    </div>
</template>

<script>
export default {
    name: 'FlashMessage',

    props: {
        type: { type: String, required: true },
    },

    data() {
        return {
            hidden: false,
        };
    },

    computed: {
        isError() {
            return this.type === 'error';
        },

        role() {
            return this.type === 'error' ? 'alert' : 'status';
        },
    },

    methods: {
        close() {
            this.hidden = true;
        },
    },
}
</script>

<style lang="scss" scoped>
.flash-message {
    position: fixed;
    top: 0;
    right: 0;
    z-index: 30;

    display: flex;
    justify-content: space-between;

    min-width: 25rem;

    margin: 1.6rem;
    padding: 1.2rem 0.8rem;

    border-radius: 0.6rem;

    color: white;
    font-weight: bold;

    &--success {
        background-color: #06d6a0
    }
    &--error {
        background-color: #ef476f;
    }

    &__content {
        display: flex;
        align-items: center;
    }

    &__close {
        position: relative;
        top: -1.2rem;

        margin-left: 0.8rem;

        cursor: pointer;

        font-size: 3rem;
        line-height: 3.6rem;
    }
}

svg {
    fill: #ffffff;
}
</style>
