<template>
    <div class="document-component">
        <div class="container">
            <div class="row">
                <div class="col-md-4">
                    <figure v-if="image && image.url" class="document-component__media">
                        <img :src="image.url" :alt="image.alt">
                    </figure>
                </div>
                <div class="col-md-8 mt-4 mt-md-0">
                    <h5>{{ category }}</h5>
                    <h2 class="text-primary">
                        {{ name }}
                    </h2>
                    <p>{{ i18n.chooseLanguageVersion }}</p>
                    <form>
                        <div class="form-row">
                            <div class="form-group col-md-4">
                                <label for="inputSelectVersion">{{ i18n.version }}</label>
                                <select id="inputSelectVersion" v-model="currentVersion" class="form-control">
                                    <option disabled value="-1" selected>
                                        {{ i18n.chooseVersion }}
                                    </option>
                                    <option v-for="(option, index) in documentData" :key="index" :value="option.name">
                                        {{ option.name }}
                                    </option>
                                </select>
                            </div>
                            <div class="form-group col-md-4">
                                <label for="inputSelectLanguage">{{ i18n.lanugage }}</label>
                                <select
                                    id="inputSelectLanguage"
                                    v-model="currentLanguage"
                                    :disabled="currentVersion === -1"
                                    class="form-control"
                                >
                                    <option value="-1" disabled selected>
                                        {{ i18n.chooseLanguage }}
                                    </option>
                                    <option
                                        v-for="(option, index) in getLanugages(currentVersion)"
                                        :key="index"
                                        :value="option"
                                    >
                                        {{ option.language }}
                                    </option>
                                </select>
                            </div>
                        </div>
                        <div class="form-row mt-3">
                            <div class="col-sm-10">
                                <button :disabled="currentLanguage === -1" class="btn btn-primary">
                                    <a
                                        :href="currentLanguage.url"
                                        target="_blank"
                                        v-text="i18n.download"
                                    />
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'DocumentComponent',
    props: {
        name: {
            type: String,
            default: ''
        },
        category: {
            type: String,
            default: ''
        },
        documentsVersions: {
            type: String,
            default: 'coucou'
        },
        image: {
            type: Object,
            default: function () {
                return {
                    url: null,
                    alt: ''
                }
            }
        },
        i18n: {
            type: Object,
            default: function () {
                return {}
            }
        }
    },
    data() {
        return {
            currentVersion: -1,
            currentLanguage: -1
        };
    },
    computed: {
        documentData() {
            return JSON.parse(this.documentsVersions);
        }
    },
    watch: {
        currentVersion(newValue, oldValue) {
            if(newValue !== oldValue) {
                this.currentLanguage = -1;
            }
        }
    },
    methods: {
        getLanugages(versionName) {
            if (this.documentsVersions) {
                let options = this.documentData.find(version => {
                    return version.name === versionName;
                });
                if (options && options.files && options.files.length > 0) {
                    return options.files;
                }
            }
            return [];
        },
        downloadClickHandler() {
            window.location.href = this.currentLanguage.url;
        }
    },
};
</script>

<style lang="scss" scoped>
    .document-component {
        //
        &__media {
            > img {
                width: 100%;
                height: auto;
            }
        }
    }

    button a {
        color: inherit;
    }
</style>
