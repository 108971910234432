<template>
    <div
        :class="{
            'VueCarousel-slide-active': isActive,
            'VueCarousel-slide-center': isCenter
        }"
        class="VueCarousel-slide"
        tabindex="-1"
    >
        <slot />
    </div>
</template>

<script>
export default {
    name: 'VueSlide',
    inject: ['vueCarousel'],
    data() {
        return {
            width: null
        };
    },
    computed: {
        activeSlides() {
            const { currentPage, perPage, $children } = this.vueCarousel;
            const activeSlides = [];
            const children = $children
                .filter(
                    child =>
                        child.$el && child.$el.className.indexOf('VueCarousel-slide') >= 0
                )
                .map(child => child._uid);

            let i = 0;
            while (i < perPage) {
                const child = children[currentPage * perPage + i];
                activeSlides.push(child);
                i++;
            }

            return activeSlides;
        },
        /**
     * `isActive` describes whether a slide is visible
     * @return {Boolean}
     */
        isActive() {
            return this.activeSlides.indexOf(this._uid) >= 0;
        },
        /**
     * `isCenter` describes whether a slide is in the center of all visible slides
     * if perPage is an even number, we quit
     * @return {Boolean}
     */
        isCenter() {
            const { perPage } = this.vueCarousel;
            if (perPage % 2 === 0 || !this.isActive) return false;
            return this.activeSlides.indexOf(this._uid) === Math.floor(perPage / 2);
        }
    },
    mounted() {
        if (!this.$isServer) {
            this.$el.addEventListener('dragstart', e => e.preventDefault());
        }

        this.$el.addEventListener(
            this.vueCarousel.isTouch ? 'touchend' : 'mouseup',
            this.onTouchEnd
        );
    },
    methods: {
        onTouchEnd(e) {
            const eventPosX =
        this.vueCarousel.isTouch && e.changedTouches && e.changedTouches.length > 0
            ? e.changedTouches[0].clientX
            : e.clientX;
            const deltaX = this.vueCarousel.dragStartX - eventPosX;

            if (
                this.vueCarousel.minSwipeDistance === 0 ||
        Math.abs(deltaX) < this.vueCarousel.minSwipeDistance
            ) {
                this.$emit('slideClick', Object.assign({}, e.currentTarget.dataset));
            }
        }
    }
};
</script>

<style lang="scss">
    .VueCarousel-slide {
        display: flex;
        flex-basis: inherit;
        flex-grow: 0;
        flex-shrink: 0;
        user-select: none;
        backface-visibility: hidden;
        -webkit-touch-callout: none;
        outline: none;
    }
</style>
